import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateMultipleShipmentPackagesInterface {
  warehouseOrderId: string
  shipmentId: string
  packageList: any[] // List of packages
}

export const useCreateMultipleShipmentPackages = () => {
  const mutate = useMutation(
    async ({
      shipmentId,
      warehouseOrderId,
      packageList,
    }: CreateMultipleShipmentPackagesInterface) => {
      const mappedPackages = packageList.map((packageData) => ({
        type: packageData.type,
        qty: Number(packageData.qty),
        width: Number(packageData.width),
        height: Number(packageData.height),
        length: Number(packageData.length),
        weight: Number(packageData.weight),
      }))

      return postAPI(
        `/WarehouseOrder/${warehouseOrderId}/Shipments/${shipmentId}/MultiplePackages`,
        mappedPackages, // Send the mapped package list to the backend
      )
    },
  )

  return mutate
}

export default useCreateMultipleShipmentPackages
