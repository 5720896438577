/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnStructure from './columnStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  setSelectedRows,
  selectedOriginalRowIds,
  isCustomized,
  setDeleteObjectDetails,
  isDeleteAccess,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = useMemo(() => {
    // Clone columnStructure to avoid modifying the original structure
    const modifiedColumns = [...columnStructure]

    // If delete access is denied, filter out the delete column
    if (!isDeleteAccess) {
      return modifiedColumns.filter((column) => column.accessor !== 'delete')
    }
    return modifiedColumns
  }, [isDeleteAccess])
  console.log('setSelectedRows in DataTable:', setSelectedRows)

  useEffect(() => {
    setData([...itemData])
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table
        data={data}
        setSelectedRows={setSelectedRows}
        loading={loading}
        columns={columns}
        selectedOriginalRowIds={selectedOriginalRowIds}
        isCustomized={isCustomized}
        setDeleteObjectDetails={setDeleteObjectDetails}
      />
    </div>
  )
}

export default DataTable
