/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import {
  useCreateMultipleShipmentPackages,
  useGetPackageTemplatesTable,
} from 'framework/api/methods'
import useDeletePackageTemplate from 'framework/api/methods/orderConsignment/useDeletePackageTemplate'

import { PopUpAlert } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'
import { DataTable as PackageTemplate } from 'components/pages/OrderConsignment/Tables/PackageTemplateTable/DataTable'

export interface AddPackageFromTemplateProps {
  isActive?: boolean | undefined
  className?: string | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  setSelectedPackages?: any
  isCustomStyles?: boolean | undefined
  selectedShipmentId?: any
  selectedOrderDetails?: any
  callOrderShipmentsById?: any
  notify?: any
  shipmentPackageDetails?: any
  setIspackageFromTemplatesModalOpen?: any
}

export const AddPackageFromTemplateModal: FC<AddPackageFromTemplateProps> = ({
  className,
  isActive,
  onCloseClick,
  onOverlayClick,
  isCustomStyles,
  selectedShipmentId,
  selectedOrderDetails,
  callOrderShipmentsById,
  notify,
  shipmentPackageDetails,
  setIspackageFromTemplatesModalOpen,
}: AddPackageFromTemplateProps) => {
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }
  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  const [isCustomized, setIsCustomized] = useState(true)
  const [packageTemplateData, setPackageTmplateData] = useState<any>([])
  const [selectedOriginalRowIds, setSelectedOriginalRowIds] = useState<any>([])
  const [selectedrows, setSelectedRows] = useState<any>([])
  const [deleteObjectDetails, setDeleteObjectDetails] = useState<any>({
    trigger: false,
    deleteData: {},
    method: '',
    deleteHeading: '',
    deleteDescription: '',
  })

  const handleDeleteCancel = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  /** Delete PopUp Modal Reset */
  const resetDeleteObjectDetails = () => {
    setDeleteObjectDetails({
      trigger: false,
      deleteData: {},
      method: '',
      deleteHeading: '',
      deleteDescription: '',
    })
  }

  const handleDeleteConfirm = () => {
    if (deleteObjectDetails.trigger) {
      if (deleteObjectDetails.method === 'deletePackageTemplate') {
        deletePackageTemplate(
          deleteObjectDetails?.deleteData?.packageTemplateId,
        )
      }
    }
  }
  const { userDetails }: any = useSelector<any>(
    (state: { user: any }) => state.user,
  )

  const checkIfPackageAlreadyAdded = (
    selectedRows: any[], // Array of selected rows
    shipmentPackageDetails: any[], // Array of existing shipment packages
  ): boolean => {
    // Ensure that both arrays are defined and are valid arrays

    // Loop through each selected row
    for (let i = 0; i < selectedRows.length; i++) {
      const { height, width, length, weight, type } = selectedRows[i]
      // Loop through shipmentPackageDetails to find a duplicate
      for (let j = 0; j < shipmentPackageDetails.length; j++) {
        const pkg = shipmentPackageDetails[j]
        if (
          pkg.height === height &&
          pkg.width === width &&
          pkg.length === length &&
          pkg.weight === weight &&
          pkg.type === type
        ) {
          return true
        }
      }
    }

    // No duplicates found
    return false
  }

  /*API call for packageTemplateDelete*/
  const {
    mutate: deletePackageTemplateMutate,
    isLoading: deletePackageTemplateIsLoading,
  } = useDeletePackageTemplate()

  /** Process the Delete Order Shipment package */
  async function deletePackageTemplate(packageTemplateId: string) {
    deletePackageTemplateMutate(
      { packageTemplateId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          resetDeleteObjectDetails()
          console.log(
            'OrderType',
            selectedOrderDetails?.partnerOrder?.orderType,
          )
          getPackageTemplates(
            isCustomized,
            selectedOrderDetails?.partnerOrder?.partner?.code,
            selectedOrderDetails?.partnerOrder?.orderType ?? '',
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }
  {
    /* Delete Pop-Ups */
  }
  /** API Call for Get package templates*/
  const {
    mutate: getPackageTemplatesMutate,
    isLoading: getPackageTemplateIsLoading,
  } = useGetPackageTemplatesTable()

  async function getPackageTemplates(
    isCustomized: boolean,
    partnerCode?: string,
    orderType?: string,
  ) {
    getPackageTemplatesMutate(
      { isCustomized, partnerCode, orderType },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedPackageTemplates = successData?.packageTemplates?.map(
            (template: any) => ({
              ...template,
              qty: 1, // or any default value you'd like
            }),
          )
          setPackageTmplateData(updatedPackageTemplates)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleAddPackages = (event: any) => {
    // Check if any selected rows are duplicates

    // Create the packageList from selectedRows
    event.stopPropagation()
    const shipmentPackgeData = selectedrows.map((row: any) => ({
      name: row.templateName,
      type: row.type,
      qty: Number(row.qty),
      width: Number(row.width),
      height: Number(row.height),
      length: Number(row.length),
      weight: Number(row.weight),
    }))
    createMultipleShipmentPackges(
      selectedOrderDetails?.id,
      selectedShipmentId,
      shipmentPackgeData,
    )
  }

  //API call for create multiple Shipment packges

  const {
    mutate: createMultipleShipmentPackagesMutate,
    isLoading: createMultipleShipmentPackagesLoading,
  } = useCreateMultipleShipmentPackages()

  async function createMultipleShipmentPackges(
    warehouseOrderId: string,
    shipmentId: string,
    packageList: any[],
  ) {
    createMultipleShipmentPackagesMutate(
      { warehouseOrderId, shipmentId, packageList },
      {
        onSuccess: ({ data: successData }: any) => {
          callOrderShipmentsById()
          setIspackageFromTemplatesModalOpen(false)
          notify('Packages Successfully Added', 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  useEffect(() => {
    getPackageTemplates(
      isCustomized,
      selectedOrderDetails?.partnerOrder?.partner?.code,
      selectedOrderDetails?.partnerOrder?.orderType ?? '',
    )
  }, [isCustomized])

  const modalClasses = CN(
    'fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
    className,
    {
      hidden: !isActive,
    },
  )
  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[1000px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/*onClick={(e) => e.stopPropagation()}>*/}
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Add Package From Template</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        {/* Modal Body */}
        <div className='p-4'>
          <div className='flex justify-between mb-4'>
            <div className='flex items-center mr-8'>
              <label className='flex items-center cursor-pointer'>
                <input
                  type='radio'
                  name='packageType'
                  checked={!isCustomized}
                  onChange={() => setIsCustomized(false)}
                  className='mr-2'
                />
                <span>Standard</span>
              </label>
              <label className='flex items-center ml-4 cursor-pointer'>
                <input
                  type='radio'
                  name='packageType'
                  checked={isCustomized}
                  onChange={() => setIsCustomized(true)}
                  className='mr-2'
                />
                <span>Customized</span>
              </label>
            </div>
          </div>
          {deleteObjectDetails.trigger && (
            <PopUpAlert
              isActive={deleteObjectDetails.trigger}
              title={deleteObjectDetails?.deleteHeading}
              message={deleteObjectDetails?.deleteDescription}
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => handleDeleteCancel()}
              onOverlayClick={() => handleDeleteCancel()}
              onConfirmClick={() => handleDeleteConfirm()}
            />
          )}

          <PackageTemplate
            itemData={packageTemplateData}
            className=''
            setSelectedRows={setSelectedRows}
            selectedOriginalRowIds={selectedOriginalRowIds}
            isCustomized={isCustomized}
            setDeleteObjectDetails={setDeleteObjectDetails}
            isDeleteAccess={userDetails?.userPermissions?.find(
              (item: any) => item === 'PackageTemplateEditAccess',
            )}
          />
        </div>

        {/* Modal Footer */}
        <div className='flex justify-end p-6 border-t border-gray-200 w-full'>
          <Button
            className='mr-4'
            disabled={selectedrows.length == 0}
            onClick={(e: any) => {
              handleAddPackages(e)
            }}>
            Add Package
          </Button>
          <Button appearance='dull' onClick={handleOnCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}
AddPackageFromTemplateModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}
