import React from 'react'

import { Badge } from 'components/atoms/Badge'
import { dateAndTImeToReadableString } from 'utils'

export const columns = [
  {
    customWidth: 150,
    Header: () => 'Order No',
    accessor: 'orderNo',
    Cell: ({ row }) => <div>{row.original.orderNo}</div>,
  },
  {
    customWidth: 180,
    Header: () => 'WMS Order',
    accessor: 'wmsOrder',
    Cell: ({ row }) => <div>{row.original.wmsOrder}</div>,
  },
  {
    customWidth: 180,
    Header: 'Warehouse Code',
    accessor: 'warehouseCode',
    Cell: ({ row }) => <div>{row.original.warehouseCode}</div>,
  },
  {
    customWidth: 180,
    Header: 'Couriers',
    accessor: 'courierCode',
    Cell: ({ row }) => <div>{row.original.courierCode}</div>,
  },
  {
    customWidth: 180,
    Header: 'Principle',
    accessor: 'principal',
    Cell: ({ row }) => <div>{row.original.principal}</div>,
  },
  {
    customWidth: 254,
    Header: () => 'Date and Time of Consignment',
    accessor: 'updatedOn',
    Cell: ({ row }) => (
      <div>{dateAndTImeToReadableString(row.original.createdOn)}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Manifest Status',
    accessor: 'isActive',
    Cell: ({ row }) => (
      <div>
        {row.original.isManifest ? (
          <Badge appearance='success' labelValue='Manifested' />
        ) : (
          <Badge appearance='warning' labelValue='Pending' />
        )}
      </div>
    ),
  },
]
export default columns
