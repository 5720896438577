import { FC } from 'react'
import CN from 'classnames'

export interface OrderTypeLabelProps {
  className?: string | undefined
  labelValue?: string | undefined
}

export const OrderTypeLabel: FC<OrderTypeLabelProps> = ({
  className,
  labelValue,
}: OrderTypeLabelProps) => {
  return (
    <div
      className={CN(
        'flex items-center justify-center',
        'rounded-full w-6 h-5', // Ensures it’s a circle; adjust w-8 and h-8 as needed
        'bg-[rgba(187,185,185,0.62)] bg-opacity-[70%]',
      )}>
      <p className={CN('font-bold text-xs text-center', 'text-N-700')}>
        {labelValue}
      </p>
    </div>
  )
}
OrderTypeLabel.defaultProps = {
  className: undefined,
  labelValue: undefined,
}

export default OrderTypeLabel
