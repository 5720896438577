/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useCancelConsignmentOrders,
  useDeletePartnerOrderOrderLines,
  useGetPartnerList,
  useGetPartnerOrderDetailsById,
  useGetPartnerOrders,
  useGetPartnerOrderTypes,
  usePushOrder,
  useUpdateOrderLineById,
  useUpdatePartnerOrder,
  useUpdatePartnerOrderStatus,
} from 'framework/api/methods'

import { PopUpAlert, StatusTimeLine } from 'components/atoms'
import { Button } from 'components/atoms/Button/Button'
import { JsonLoader } from 'components/atoms/JsonLoader'
import { JsonSearchEmpty } from 'components/atoms/JsonSearchEmpty'
import { PopOut } from 'components/atoms/PopOut'
import { OrderCard } from 'components/molecules/OrderCard'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

import { OrderFooter } from './sections/OrderFooter'
import { AdvanceSearchModal } from './AdvanceSearchModal'
import { DataTable } from './OrderLineDataTable'
import {
  FilterChipSection,
  FooterWithPagination,
  OrderHeader,
  ShippingDetailEditSection,
  ShippingDetailViewSection,
} from './sections'

import 'react-toastify/dist/ReactToastify.css'

export interface OrdersProps {
  [x: string]: any
}

/** This is Orders */

export const Orders: FC<OrdersProps> = ({ className }: OrdersProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const partnerOrderEditAccess = userDetails?.userPermissions?.includes(
    'PartnerOrderEditAccess',
  )

  const date = new Date()
  const startDateValue = new Date(date.getFullYear(), date.getMonth() - 2, 1)
  const endDateValue = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
  )
  const [partnerOrderVariables, setPartnerOrderVariables] = useState<any>({
    take: 10,
    skip: 0,
    Search: '',
    partner: '',
    status: ['ALL'],
    isInternational: false,
    from:
      startDateValue.getFullYear() +
      '-' +
      ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
      '-' +
      startDateValue.getDate(),
    to:
      endDateValue.getFullYear() +
      '-' +
      ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
      '-' +
      endDateValue.getDate(),
    ordertype: '',
  })

  /** Pagination & Table Related States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })
  const [searchKeyword, setSearchKeyword] = useState('')
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [filterSectionKey, setFilterSectionKey] = useState(Math.random())

  const [partnerOrders, setPartnerOrders] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [orderDetails, setOrderDetails] = useState<any>({})
  const [selectedOrder, setSelectedOrder] = useState<any>({ id: '' })

  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const [showCancelConsignmentOrderModal, setShowCancelConsignmentOrderModal] =
    useState(false)

  const [deleteOrderLinePopOut, setDeleteOrderLinePopOut] = useState({
    enable: false,
    orderLineId: '',
  })
  const [isDelivered, setIsDelivered] = useState(false)

  const [
    partnerOrderAdvancedSearchModalIsOpen,
    setPartnerOrderAdvancedSearchModalIsOpen,
  ] = useState(false)
  const [isShippingDetailUpdateView, setIsShippingDetailUpdateView] =
    useState(false)
  const [editViewShippingDetails, setEditViewShippingDetails] = useState<any>(
    {},
  )

  const [orderTypeList, setOrderTypeList] = useState<OrderType[]>([])

  interface OrderType {
    value: string
    label: string
  }

  /** Toaster Function */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** Get Partner Order Details By Id Success Function */
  const getPartnerOrderDetailsByIdOnSuccess = (data: any) => {
    const { partnerOrder } = data || {}
    setOrderDetails(partnerOrder)
    setIsDelivered(partnerOrder?.isDelivered)
    setEditViewShippingDetails({
      ...editViewShippingDetails,
      customerFirstName: partnerOrder?.customerFirstName || '',
      customerLastName: partnerOrder?.customerLastName || '',
      customerEmail: partnerOrder?.customerEmail || '',
      customerPhoneNo: partnerOrder?.customerPhone || '',
      customerAddressLine01: partnerOrder?.shippingAddress?.lineOne || '',
      customerAddressLine02: partnerOrder?.shippingAddress?.lineTwo || '',
      customerSuburb: partnerOrder?.shippingAddress?.suburb || '',
      customerState: partnerOrder?.shippingAddress?.state || '',
      customerCountry: partnerOrder?.shippingAddress?.country || '',
      customerCountryCode: partnerOrder?.shippingAddress?.countryCode || '',
      customerPostalCode: partnerOrder?.shippingAddress?.postCode || '',
      shippingNote: partnerOrder?.shippingNote || '',
      warehouseNote: partnerOrder?.warehouseNote || '',
      customerStateCode: partnerOrder?.shippingAddress?.stateCode || '',
    })
    setIsShippingDetailUpdateView(false)
  }

  /** Get Partner Order Details By Id Error Function */
  const getPartnerOrderDetailsByIdOnError = (error: any) => {
    notify(error, 'error')
  }

  /** API Call for Get Partner Order Details By Id */
  const {
    isLoading: GetPartnerOrderDetailsByIdIsLoading,
    refetch: callGetPartnerOrderDetailsById,
  } = useGetPartnerOrderDetailsById(
    {
      orderId: selectedOrder.id,
    },
    getPartnerOrderDetailsByIdOnSuccess,
    getPartnerOrderDetailsByIdOnError,
  )

  /** API Call for Order Line Update */
  const {
    mutate: updateOrderLineByIdMutate,
    isLoading: updateOrderLineByIdIsLoading,
  } = useUpdateOrderLineById()

  /** Process the Order Line Update API call */
  async function updateOrderLineById(updateVariables: any) {
    updateOrderLineByIdMutate(updateVariables, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message, 'success')
        callGetPartnerOrderDetailsById()
      },

      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call for Partner Order Shipping info Update */
  const {
    mutate: updatePartnerOrderMutate,
    isLoading: updatePartnerOrderIsLoading,
  } = useUpdatePartnerOrder()

  /** Process the Partner Order Shipping info Update API call */
  async function updatePartnerOrder() {
    const updateVariables = {
      ...editViewShippingDetails,
      id: selectedOrder.id,
    }
    updatePartnerOrderMutate(updateVariables, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || '', 'success')
        setIsShippingDetailUpdateView(false)
        callGetPartnerOrderDetailsById()
      },

      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call For Partner List (Advanced Search Modal)  */
  const { mutate: getPartnerListMutate } = useGetPartnerList()

  /** Process the get Partner List */
  async function getPartnerList() {
    getPartnerListMutate(partnerOrderVariables, {
      onSuccess: ({ data: successData }: any) => {
        setPartnerList(successData.partnerCodeNameModelList)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call For Partner order type List (Advanced Search Modal)  */
  const { mutate: getPartnerOrderTypeListMutate } = useGetPartnerOrderTypes()

  /** Process the get Partner order Type List */
  async function getPartnerOrderTypeList() {
    getPartnerOrderTypeListMutate(partnerOrderVariables, {
      onSuccess: ({ data }: any) => {
        const allOption: OrderType = {
          value: '00000000-0000-0000-0000-000000000000',
          label: 'All',
        }
        const updatedOrderTypeList: OrderType[] = [
          allOption,
          ...data.orderTypeList,
        ]
        setOrderTypeList(updatedOrderTypeList)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call For Partner Orders (Order List View)  */
  const { mutate: getPartnerOrdersMutate } = useGetPartnerOrders()

  /** Process the get Order Table Data */
  async function getPartnerOrders() {
    getPartnerOrdersMutate(partnerOrderVariables, {
      onSuccess: ({ data: successData }: any) => {
        setPartnerOrders(successData.partnerOrders)
        setTotalRowCount(successData.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call for Retry Order (Push-Order) */
  const { mutate: pushOrderMutate, isLoading: pushOrderIsLoading } =
    usePushOrder()

  /** Process the Push-Order API Data */
  async function pushOrder() {
    pushOrderMutate(
      { partnerOrderId: selectedOrder.id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'Success...', 'success')
          callGetPartnerOrderDetailsById()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** Get Order Details by clicking Item */
  useEffect(() => {
    callGetPartnerOrderDetailsById()
  }, [selectedOrder])

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(partnerOrderVariables.take) * Number(pageNumber) -
        Number(partnerOrderVariables.take) +
        1,
      end:
        Number(partnerOrderVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(partnerOrderVariables.take) * Number(pageNumber),
    })
  }, [partnerOrderVariables, totalRowCount, pageNumber])

  /** Pagination Functions */
  useEffect(() => {
    setPartnerOrderVariables({
      ...partnerOrderVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(partnerOrderVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setPartnerOrderVariables({
        ...partnerOrderVariables,
        Search: searchKeyword,
      })
      setPageNumber(1)
    }
  }

  // useEffect(() => {
  //   setPartnerOrderVariables({
  //     ...partnerOrderVariables,
  //     orderType: partnerOrderVariables.orderType
  //   })
  // })

  const handleGoBack = () => {
    setIsShippingDetailUpdateView(!isShippingDetailUpdateView)
    const partnerOrder = orderDetails
    setEditViewShippingDetails({
      ...editViewShippingDetails,
      customerFirstName: partnerOrder?.customerFirstName || '',
      customerLastName: partnerOrder?.customerLastName || '',
      customerEmail: partnerOrder?.customerEmail || '',
      customerPhoneNo: partnerOrder?.customerPhone || '',
      customerAddressLine01: partnerOrder?.shippingAddress?.lineOne || '',
      customerAddressLine02: partnerOrder?.shippingAddress?.lineTwo || '',
      customerSuburb: partnerOrder?.shippingAddress?.suburb || '',
      customerState: partnerOrder?.shippingAddress?.state || '',
      customerCountry: partnerOrder?.shippingAddress?.country || '',
      customerCountryCode: partnerOrder?.shippingAddress?.countryCode || '',
      customerPostalCode: partnerOrder?.shippingAddress?.postCode || '',
      shippingNote: partnerOrder?.shippingNote || '',
      warehouseNote: partnerOrder?.warehouseNote || '',
      customerStateCode: partnerOrder?.shippingAddress?.stateCode || '',
    })
  }

  useEffect(() => {
    getPartnerList()
    getPartnerOrderTypeList()
  }, [])

  useEffect(() => {
    getPartnerOrders()
    setFilterSectionKey(Math.random())
    setSelectedOrder({ id: '' })
  }, [partnerOrderVariables])

  /** Hide Cancel Modal */
  const hideCancelOrder = () => {
    setShowCancelOrderModal(!showCancelOrderModal)
  }

  /** Hide Cancel Modal */
  const hideCancelConsignmentOrder = () => {
    setShowCancelConsignmentOrderModal(!showCancelConsignmentOrderModal)
  }

  /** Confirm Cancel Order */
  const {
    mutate: updatePartnerOrderStatusMutate,
    isLoading: updateCancelOrderIsLoading,
  } = useUpdatePartnerOrderStatus()

  const confirmCancelOrder = () => {
    updatePartnerOrderStatusMutate(
      {
        partnerOrderId: selectedOrder.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          getPartnerOrders()
          callGetPartnerOrderDetailsById()
          setShowCancelOrderModal(!showCancelOrderModal)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** Confirm Cancel Consignment Order */
  const {
    mutate: cancelConsignmentOrdersMutate,
    isLoading: cancelConsignmentOrdersIsLoading,
  } = useCancelConsignmentOrders()

  const confirmCancelConsignmentOrder = () => {
    hideCancelConsignmentOrder()
    cancelConsignmentOrdersMutate(
      {
        partnerOrderId: selectedOrder.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          getPartnerOrders()
          callGetPartnerOrderDetailsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** API Call for Delete Partner Order Order Line */
  const {
    mutate: deletePartnerOrderOrderLinesMutate,
    isLoading: deletePartnerOrderOrderLinesMutateIsLoading,
  } = useDeletePartnerOrderOrderLines()

  /** Process the Delete Partner Order Order Line */
  async function deletePartnerOrderOrderLines(
    orderId: string,
    orderLineId: string,
  ) {
    deletePartnerOrderOrderLinesMutate(
      { orderId, orderLineId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')

          setDeleteOrderLinePopOut({
            ...deleteOrderLinePopOut,
            enable: false,
            orderLineId: '',
          })

          callGetPartnerOrderDetailsById()
          getPartnerOrders()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const permissionForConsignOrderCancel =
    userDetails?.userPermissions?.includes('OrderConsignmentCancel')

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      {/* Left Side Order Card view table  */}

      <div className='left-column flex flex-col h-full w-1/3 rounded-lg bg-white shadow-md'>
        <div className='flex items-center w-full bg-white shadow-md py-[10px] px-4 rounded-t-lg'>
          <div className='flex flex-col w-full relative'>
            <input
              onChange={(e: any) => {
                setSearchKeyword(e.target.value)
              }}
              onKeyPress={(e: any) => {
                handleSearchKeyPress(e)
              }}
              type='text'
              placeholder='Search by WMS order, Partner'
              className='pr-3 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
            />
            <i
              className='ri-search-2-line absolute top-[6px] left-[15px] text-lg text-N-500 cursor-pointer'
              onClick={() => {
                setPaginationVariables({
                  ...partnerOrderVariables,
                  Search: searchKeyword,
                })
                setPageNumber(1)
              }}></i>
          </div>
          <button
            onClick={() => {
              setPartnerOrderAdvancedSearchModalIsOpen(true)
            }}
            className='flex items-center justify-center w-8 h-9 bg-white border border-N-200 rounded ml-2 relative'>
            <i className='ri-filter-2-line text-N-500'></i>
            {((partnerOrderVariables.status[0] !== 'ALL' &&
              partnerOrderVariables.status !== '') ||
              !(
                partnerOrderVariables.from.toString() ===
                  (
                    startDateValue.getFullYear() +
                    '-' +
                    ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
                    '-' +
                    startDateValue.getDate()
                  ).toString() &&
                partnerOrderVariables.to.toString() ===
                  (
                    endDateValue.getFullYear() +
                    '-' +
                    ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
                    '-' +
                    endDateValue.getDate()
                  ).toString()
              ) ||
              partnerOrderVariables.isInternational === true ||
              partnerOrderVariables.partner.value !== undefined) && (
              <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-3px] right-[-3px]'></div>
            )}
          </button>
        </div>
        <div className='flex flex-col items-center justify-start w-full h-full overflow-y-auto styled-scroll'>
          {partnerOrders.length > 0 &&
            partnerOrders.map((order: any, idx: any) => (
              <OrderCard
                key={idx}
                orderId={order.customerOrderNo}
                orderStatus={capitalizeFirstLetter(order.status)}
                partnerId={order.partner.code}
                partnerName={order.partner.name}
                orderDate={order.createdOn}
                integrationType={order.integrationType}
                wmsId={order.warehouseOrder?.orderNo || '-'}
                selectedOrder={selectedOrder}
                orderUid={order.id}
                orderType={order.orderType}
                onClick={() => {
                  setSelectedOrder({
                    ...selectedOrder,
                    id: order.id,
                  })
                }}
              />
            ))}
        </div>
        {/* Left Side Order Card view table pagination Section  */}
        <FooterWithPagination
          partnerOrderVariables={partnerOrderVariables}
          setPageNumber={setPageNumber}
          setPartnerOrderVariables={setPartnerOrderVariables}
          paginationVariables={paginationVariables}
          totalRowCount={totalRowCount}
          pageNumber={pageNumber}
        />
      </div>

      {/* Right side panel  */}
      <div className='right-column flex flex-col w-2/3 h-full ml-4 relative rounded-b-md'>
        {/* Right side panel Filter Chips  */}

        <FilterChipSection
          filterSectionKey={filterSectionKey}
          partnerOrderVariables={partnerOrderVariables}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setPartnerOrderVariables={setPartnerOrderVariables}
          setPageNumber={setPageNumber}
        />

        <div className='flex flex-col w-full rounded-lg bg-white shadow-md h-full overflow-y-auto styled-scroll'>
          {/* Right side panel Animation  */}
          {!selectedOrder.id && (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <JsonSearchEmpty size='sm' />
              <p className='font-2xl font-medium text-N-700'>
                No Order Selected
              </p>
              <p className='text-N-500 font-normal pt-5'>
                Please select a order from the left panel to see details
              </p>
            </div>
          )}

          {(GetPartnerOrderDetailsByIdIsLoading ||
            updatePartnerOrderIsLoading ||
            pushOrderIsLoading ||
            updateCancelOrderIsLoading ||
            updateOrderLineByIdIsLoading ||
            deletePartnerOrderOrderLinesMutateIsLoading ||
            cancelConsignmentOrdersIsLoading) && (
            <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[2] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
              <JsonLoader size='xs' />
            </div>
          )}

          {selectedOrder.id && (
            <div className='flex flex-col h-full w-full bg-white'>
              {/* Right side panel Header  */}

              <OrderHeader orderDetails={orderDetails} />

              <StatusTimeLine
                currentStatus={
                  !isDelivered
                    ? capitalizeFirstLetter(orderDetails?.status || '-')
                    : 'Delivered'
                }
                partnerOrderHistory={orderDetails?.partnerOrderHistory || []}
              />

              {/* Right side panel Body  */}

              <div className='body_panel w-full h-[calc(100%-77px)] rounded-b overflow-y-auto styled-scroll'>
                {/* order cancel display  */}
                {capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Cancelled' && (
                  <div className='flex w-full h-12 items-center bg-S-100 pl-9 mt-4'>
                    <i className='ri-error-warning-fill mr-[14px] text-xl text-N-500'></i>
                    <span className='text-base text-S-700 font-bold'>
                      Order has been cancelled
                    </span>
                  </div>
                )}

                {/* WhereHouse Error Display  */}
                {orderDetails?.warehouseOrderErrors?.length > 0 &&
                  capitalizeFirstLetter(orderDetails?.status || '-') ===
                    'Failed' && (
                    <div className='flex flex-col w-full px-4'>
                      {orderDetails?.warehouseOrderErrors.map(
                        (warehouseOrderError: any) => (
                          <PopOut
                            key={warehouseOrderError.id}
                            className='w-full mt-4'
                            appearance='error'
                            size='default'
                            isOrderPopOut={true}
                            isSelfCloseByClick={true}
                            description='Please find the detailed error message in order lines.'>
                            {warehouseOrderError.description}
                          </PopOut>
                        ),
                      )}
                    </div>
                  )}

                {/* Right side panel Body [Shipping Details]  */}
                <div
                  className='flex w-full pt-4 flex-col'
                  key={orderDetails?.id}>
                  <div className='flex w-full items-center justify-between px-4'>
                    <p className='font-bold'>SHIPPING DETAILS</p>
                    <div className='flex items-center'>
                      {isShippingDetailUpdateView && (
                        <Button
                          appearance='outline'
                          size='sm'
                          className='px-2 mr-2'
                          onClick={() => {
                            handleGoBack()
                          }}>
                          <i className='ri-arrow-go-back-line'></i>
                        </Button>
                      )}
                      {capitalizeFirstLetter(orderDetails?.status || '-') !==
                        'Cancelled' &&
                        capitalizeFirstLetter(orderDetails?.status || '-') !==
                          'Closed' &&
                        partnerOrderEditAccess && (
                          <Button
                            appearance='outline'
                            size='sm'
                            className='px-2'
                            onClick={() => {
                              if (!isShippingDetailUpdateView) {
                                setIsShippingDetailUpdateView(
                                  !isShippingDetailUpdateView,
                                )
                              } else {
                                updatePartnerOrder()
                              }
                            }}>
                            {isShippingDetailUpdateView ? (
                              <i className='ri-save-line mr-2'></i>
                            ) : (
                              <i className='ri-edit-line mr-2'></i>
                            )}
                            {isShippingDetailUpdateView ? 'Save' : 'Update'}
                          </Button>
                        )}
                    </div>
                  </div>

                  {/* Right side panel Body [Shipping Details - View]  */}
                  {!isShippingDetailUpdateView && (
                    <ShippingDetailViewSection orderDetails={orderDetails} />
                  )}

                  {/* Right side panel Body [Shipping Details - Edit]  */}
                  {isShippingDetailUpdateView && (
                    <ShippingDetailEditSection
                      setEditViewShippingDetails={setEditViewShippingDetails}
                      editViewShippingDetails={editViewShippingDetails}
                    />
                  )}

                  {/* Right side panel Order Line Table  */}
                  <div className='flex w-full flex-col'>
                    <div className='flex flex-col w-full items-start px-4 pt-4 pb-2'>
                      <p className='font-bold mb-5'>ORDER LINES</p>
                      <DataTable
                        itemData={orderDetails?.partnerOrderLines}
                        updateOrderLineById={updateOrderLineById}
                        partnerOrderId={orderDetails?.id}
                        partnerOrderStatus={orderDetails?.status}
                        deleteOrderLinePopOut={deleteOrderLinePopOut}
                        setDeleteOrderLinePopOut={setDeleteOrderLinePopOut}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {capitalizeFirstLetter(orderDetails?.status || '-') ===
                'Failed' && (
                <OrderFooter
                  isRetryOrderBtn
                  onClick={() => {
                    pushOrder()
                  }}
                  isLoading={pushOrderIsLoading}
                  onClickCancelButton={() => {
                    setShowCancelOrderModal(!showCancelOrderModal)
                  }}
                />
              )}

              {(capitalizeFirstLetter(orderDetails?.status || '-') ===
                'Confirmed' ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Received' ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Backorder' ||
                capitalizeFirstLetter(orderDetails?.status || '-') === 'Open' ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Tobeconsigned' ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Exception') && (
                <OrderFooter
                  isLoading={updateCancelOrderIsLoading}
                  onClickCancelButton={() => {
                    setShowCancelOrderModal(!showCancelOrderModal)
                  }}
                />
              )}

              {(capitalizeFirstLetter(orderDetails?.status || '-') ===
                'Closed' ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  'Partial-consigned') &&
                permissionForConsignOrderCancel && (
                  <OrderFooter
                    isCancelConsignmentOrderBtn
                    isCancelOrderBtn={false}
                    isLoading={cancelConsignmentOrdersIsLoading}
                    onClickCancelConsignmentButton={() => {
                      setShowCancelConsignmentOrderModal(
                        !showCancelConsignmentOrderModal,
                      )
                    }}
                    orderDetails={orderDetails}
                  />
                )}
            </div>
          )}
        </div>
      </div>
      {partnerOrderAdvancedSearchModalIsOpen && (
        <AdvanceSearchModal
          setPartnerOrderVariables={setPartnerOrderVariables}
          partnerOrderVariables={partnerOrderVariables}
          partnerList={partnerList}
          isActive={true}
          onCloseClick={() => {
            setPartnerOrderAdvancedSearchModalIsOpen(false)
          }}
          searchKeyword={searchKeyword}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setPageNumber={setPageNumber}
          orderTypeList={orderTypeList}
        />
      )}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
      <PopUpAlert
        isActive={showCancelOrderModal}
        title='Cancel Order?'
        message='Are you sure you want to proceed? This is not reversible.'
        cancelButtonText='Cancel'
        confirmButtonText='Yes, I’m Sure'
        onCancelClick={hideCancelOrder}
        onOverlayClick={hideCancelOrder}
        onConfirmClick={confirmCancelOrder}
      />

      <PopUpAlert
        isActive={showCancelConsignmentOrderModal}
        title='Cancel Consignment Order?'
        message='Are you sure you want to proceed? This is not reversible.'
        cancelButtonText='Cancel'
        confirmButtonText='Yes, I’m Sure'
        onCancelClick={hideCancelConsignmentOrder}
        onOverlayClick={hideCancelConsignmentOrder}
        onConfirmClick={confirmCancelConsignmentOrder}
      />

      {deleteOrderLinePopOut && (
        <PopUpAlert
          isActive={deleteOrderLinePopOut?.enable}
          title='Delete Order Line?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() =>
            setDeleteOrderLinePopOut({
              ...deleteOrderLinePopOut,
              enable: false,
              orderLineId: '',
            })
          }
          onOverlayClick={() =>
            setDeleteOrderLinePopOut({
              ...deleteOrderLinePopOut,
              enable: false,
              orderLineId: '',
            })
          }
          onConfirmClick={() => {
            deletePartnerOrderOrderLines(
              selectedOrder.id,
              deleteOrderLinePopOut?.orderLineId,
            )
          }}
        />
      )}
    </div>
  )
}

Orders.defaultProps = {}

export default Orders
