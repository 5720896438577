/* eslint-disable no-undef */
import { dateToAEST } from 'utils'

export const columns = [
  {
    customWidth: 160,
    Header: () => 'Template Name',
    accessor: 'templateName',
    Cell: ({ row }) => <div>{row.original.templateName}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Type',
    accessor: 'type',
    Cell: ({ row }) => <div>{row.original.type}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Height',
    accessor: 'height',
    Cell: ({ row }) => <div>{row.original.height}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Width',
    accessor: 'width',
    Cell: ({ row }) => <div>{row.original.width}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Length',
    accessor: 'length',
    Cell: ({ row }) => <div>{row.original.length}</div>,
  },
  {
    customWidth: 100,
    Header: () => 'Weight',
    accessor: 'weight',
    isWeight: true,
    Cell: ({ row }) => <div>{row.original.weight}</div>,
  },
  {
    customWidth: 100,
    Header: () => 'Quantity',
    accessor: 'quantity',
    isQuantity: true,
    Cell: ({ row }) => <div>{row.original.quantity}</div>,
  },
  {
    customWidth: 100,
    Header: () => '',
    accessor: 'delete',
    isAction: true,
  },
]

export default columns
