/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { CheckBox } from 'components/atoms/Checkbox'
import { Input } from 'components/atoms/Input'
import { NumberInput } from 'components/atoms/NumberInput/NumberInput'
import { QuantityPicker } from 'components/atoms/QuantityPicker'

const Table = ({
  columns,
  data,
  setSelectedRows,
  selectedOriginalRowIds,
  setDeleteObjectDetails,
  deleteObjectDetails,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleRowSelected,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        selectedRowIds: {}, // Ensure this is an empty object to prevent auto-selection
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        {
          id: 'selection',
          customWidth: 32,
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <CheckBox {...getToggleAllPageRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <CheckBox
              {...row.getToggleRowSelectedProps()}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        },
        ...cols,
      ])
    },
  )

  useEffect(() => {
    console.log('data', data)
  }, [data])

  const handleQuantityChange = (row, value) => {
    if (value >= 0) {
      const updatedData = data.map((item) => {
        if (item.templateName === row.original.templateName) {
          item.qty = value
        }
        return item
      })
      // Update state with updatedData, or handle as necessary
    }
  }

  const handleWeightChange = (row, value) => {
    if (value >= 0) {
      const updatedData = data.map((item) => {
        if (item.templateName === row.original.templateName) {
          item.weight = value
        }

        return item
      })
      // Update state with updatedData, or handle as necessary
    }
  }

  useEffect(() => {
    console.log('selectedRowIds', selectedOriginalRowIds)
    if (data?.length > 0 && selectedOriginalRowIds?.length > 0) {
      selectedOriginalRowIds.forEach((id) => {
        const rowIndex = data.findIndex((item) => item.id === id)
        if (rowIndex !== -1) {
          toggleRowSelected(rowIndex.toString(), true) // Select rows based on the original IDs provided
        }
      })
    } else {
      // Deselect all rows if selectedOriginalRowIds is empty or undefined
      Object.keys(setSelectedRows).forEach((rowId) => {
        toggleRowSelected(rowId, false)
      })
    }
  }, [selectedOriginalRowIds, data, toggleRowSelected])

  const slicedPageData = page

  useEffect(() => {
    const selectedRows = selectedFlatRows.map((row) => row.original)
    setSelectedRows(selectedRows) // Update the selected rows in the parent component
  }, [selectedFlatRows, setSelectedRows])

  return (
    <div className='styled-scroll h-full relative overflow-y-auto rounded border border-N-200'>
      <table className='w-full overflow-y-auto' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={CN(
                    'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                    {
                      'bg-N-200': column.isSorted,
                    },
                  )}
                  style={{ height: 40 }}
                  width={column.customWidth}>
                  <div
                    className='inline-flex items-center'
                    style={{ minHeight: 20 }}>
                    {column.render('Header')}
                    <span className='flex ml-1' style={{ width: 14 }}>
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <i className='ri-arrow-up-s-line' />
                        ) : (
                          <i className='ri-arrow-down-s-line' />
                        ))}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {slicedPageData.map((row) => {
            prepareRow(row)
            return (
              <tr
                className={CN('h-11 cursor-pointer')}
                {...row.getRowProps()}
                onClick={() => {
                  row.toggleRowSelected() // Allow clicking the row to toggle selection
                }}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={CN(
                      'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                    )}>
                    {cell.column.isWeight ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className='flex items-center'>
                        <NumberInput
                          value={row.original.weight}
                          defaultValue={row.original.weight}
                          isMinusEnabled={false}
                          placeholder={row.original.weight}
                          isWide={true}
                          onChange={(e, val) => {
                            handleWeightChange(row, val)
                          }}
                        />
                      </div>
                    ) : cell.column.isQuantity ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className='flex items-center'>
                        <QuantityPicker
                          value={row.original.qty || 0}
                          isMinusEnabled={false}
                          defaultValue={1}
                          onChange={(e, val) => {
                            handleQuantityChange(row, val)
                          }}
                        />
                      </div>
                    ) : cell.column.isAction ? (
                      <div className='flex items-center w-fit'>
                        <Button
                          onClick={() => {
                            setDeleteObjectDetails({
                              ...deleteObjectDetails,
                              trigger: true,
                              deleteData: {
                                packageTemplateId: row.original.id,
                              },
                              method: 'deletePackageTemplate',
                              deleteHeading: 'Delete Package Template?',
                              deleteDescription:
                                'Are you sure you want to proceed? This is not reversible.',
                            })
                          }}
                          size='xs'
                          appearance='danger'>
                          <i className='ri-delete-bin-6-line text-R-500'></i>
                        </Button>
                      </div>
                    ) : (
                      cell.render('Cell')
                    )}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

Table.propTypes = {}

export default Table
