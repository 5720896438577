/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'
import starshipitIcon from 'assets/images/starshipitIcon.svg'
import CN from 'classnames'

import { OrderStatusLabel } from 'components/atoms/OrderStatusLabel'
import { OrderTypeLabel } from 'components/atoms/OrderTypeLabel'
import { dateToAEST } from 'utils'

export interface OrderStatusLabelProps {
  className?: string | undefined
  orderId?: string | undefined
  partnerId?: string | undefined
  partnerName?: string | undefined
  orderDate?: any | undefined
  wmsId?: string | undefined
  orderStatus?: any
  integrationType?: string | undefined
  onClick?: () => void
  selectedOrder: any
  orderUid: string
  orderType?: string
}

export const OrderCard: FC<OrderStatusLabelProps> = ({
  className,
  orderId,
  partnerId,
  partnerName,
  orderDate,
  wmsId,
  orderStatus,
  integrationType,
  onClick,
  selectedOrder,
  orderUid,
  orderType,
}: OrderStatusLabelProps) => {
  /** convert to AEST Time zone */
  const dateString = dateToAEST(orderDate)

  return (
    <div
      className={CN(
        'flex flex-col w-full p-3 bg-white border-t border-t-N-50 border-b border-b-N-50 cursor-pointer hover:bg-N-50 active:bg-N-100',
        {
          'bg-N-100 border-l-4 border-l-P-500': selectedOrder?.id === orderUid,
        },
        className,
      )}
      onClick={onClick}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center space-x-2 w-3/4'>
          {/* Order ID with Tooltip */}
          <div data-tip={orderId}>
            <ReactTooltip
              place='top'
              effect='solid'
              delayShow={200}
              textColor='#E2E8F0'
              backgroundColor='#334155'
            />
            <p className='font-bold line-clamp-1'>#{orderId}</p>
          </div>

          {/* Order Status Label */}
          <OrderStatusLabel appearance={orderStatus} labelValue={orderStatus} />

          {/* Integration Type Icon */}
          {integrationType === 'Woocommerce' && (
            <img
              src={Iconwoocommerce}
              alt='Woocommerce Icon'
              className='ml-2'
            />
          )}
          {integrationType === 'Shopify' && (
            <img src={IconShopify} alt='Shopify Icon' className='ml-2' />
          )}
          {integrationType === 'PushApi' && (
            <img src={IconPushAPI} alt='PushAPI Icon' className='ml-2' />
          )}
          {integrationType === 'IcsWMS' && (
            <i className='ri-store-3-fill text-base text-N-600'></i>
          )}
          {integrationType === 'AzureSftp' && (
            <i className='ri-folder-received-fill text-base text-N-600'></i>
          )}
          {integrationType === 'StarshipIT' && (
            <img src={starshipitIcon} alt='StarshipIT Icon' className='ml-2' />
          )}

          {/* Order Type Label */}
          {orderType && <OrderTypeLabel labelValue={orderType} />}
        </div>

        {/* Date */}
        <div className='w-1/4 text-right'>
          <p className='text-xs font-medium'>{dateString}</p>
        </div>
      </div>
      <div className='flex w-full items-center justify-between'>
        <div className='w-fit pt-1'>
          <p className='text-sm font-semibold text-N-600'>
            {partnerId} - {partnerName}
          </p>
        </div>
        {/* <div className={CN('flex w-3/4', className)}>
          {orderType && <OrderTypeLabel labelValue={orderType} />}
        </div> */}
      </div>
      <div className='w-full pt-1'>
        <p className='text-xs font-normal text-N-500'>WMS Order #: {wmsId}</p>
      </div>
    </div>
  )
}

OrderCard.defaultProps = {
  className: undefined,
  orderId: undefined,
  partnerId: undefined,
  partnerName: undefined,
  orderDate: undefined,
  wmsId: undefined,
  orderStatus: undefined,
}

export default OrderCard
