/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useCreatePackageTemplate } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'

export interface AddNewPackageModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  notify?: any
  shipmentPackageDetails?: any
  setShipmentPackageDetails?: any
  useCreateShipmentPackage?: any
  callOrderShipmentsById?: any
  selectedShipmentId?: any
  selectedOrderDetails?: any
}

export const AddNewPackageModal: FC<AddNewPackageModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  shipmentPackageDetails,
  setShipmentPackageDetails,
  useCreateShipmentPackage,
  callOrderShipmentsById,
  selectedShipmentId,
  selectedOrderDetails,
  notify,
}: AddNewPackageModalProps) => {
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }
  const { userDetails }: any = useSelector<any>(
    (state: { user: any }) => state.user,
  )

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }
  const [isCustomized, setIsCustomized] = useState(true)

  const packageTypes = [
    {
      value: 'Carton',
      label: 'Carton',
    },
    {
      value: 'Pallet',
      label: 'Pallet',
    },
    {
      value: 'Envelope',
      label: 'Envelope',
    },
    {
      value: 'Satchel',
      label: 'Satchel',
    },
    {
      value: 'Paper Roll',
      label: 'Paper Roll',
    },
  ]

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true)

  const [saveForLater, setSaveForLater] = useState(false)
  const [partnerCode, setPartnerCode] = useState(
    selectedOrderDetails?.partnerOrder?.partner?.code,
  )
  const [orderType, setOrderType] = useState(
    selectedOrderDetails?.partnerOrder?.orderType ?? '',
  )
  const [templateName, setTemplateName] = useState('')

  useEffect(() => {
    if (
      !validatePackageDimensions(
        Number(shipmentPackageDetails?.length),
        Number(shipmentPackageDetails?.width),
        Number(shipmentPackageDetails?.height),
        Number(shipmentPackageDetails?.weight),
      ) &&
      templateName == ''
    ) {
      setIsConfirmButtonDisabled(true)
    } else {
      setIsConfirmButtonDisabled(false)
    }
  }, [shipmentPackageDetails])

  const validatePackageDimensions = (
    length: number,
    width: number,
    height: number,
    weight: number,
  ) => {
    let count = 0
    // Check each dimension.
    if (length > 0) {
      count++
    }
    if (width > 0) {
      count++
    }
    if (height > 0) {
      count++
    }
    if (weight > 0 && count > 2) {
      return true
    } else {
      return false
    }
  }

  /** API Call for Create Shipment Package */
  const {
    mutate: createShipmentPackageMutate,
    isLoading: loadingCreatePackage,
  } = useCreateShipmentPackage()

  /** Process the Create Shipment Package */
  async function createShipmentPackage(
    wereHouseOrderId: string,
    shipmentId: string,
    packageData: any,
  ) {
    createShipmentPackageMutate(
      { wereHouseOrderId, shipmentId, packageData },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          if (onCloseClick) {
            onCloseClick()
          }
          callOrderShipmentsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  /** API Call for Create Package Template */
  const {
    mutate: createPackageTemplateMutate,
    isLoading: loadingCreatePackageTemplate,
  } = useCreatePackageTemplate()

  /** Process the Create  Package Template */
  async function createPackageTemplate(
    partnerCode: string,
    orderType: string,
    templateName: string,
    isCustomized: boolean,
    packageData: any,
  ) {
    createPackageTemplateMutate(
      { partnerCode, orderType, templateName, isCustomized, packageData },
      {
        onSuccess: ({ data: successData }: any) => {
          notify('Package template created successfully', 'success')
          if (onCloseClick) {
            onCloseClick()
          }
          createShipmentPackage(
            selectedOrderDetails.id,
            selectedShipmentId,
            shipmentPackageDetails,
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }
  const handleTemplateTypeChange = (e: any) => {
    const value = e.target.value
    if (value == 'Customized') {
      const partnerCode = selectedOrderDetails?.partnerOrder?.partner?.code
      setPartnerCode(partnerCode)
      setIsCustomized(true)
    } else {
      setPartnerCode('')
      setIsCustomized(false)
    }
  }

  const handleConfirmClick = (event: any) => {
    event.stopPropagation()

    if (saveForLater) {
      createPackageTemplate(
        partnerCode,
        orderType,
        templateName,
        isCustomized,
        shipmentPackageDetails,
      )
    } else {
      createShipmentPackage(
        selectedOrderDetails.id,
        selectedShipmentId,
        shipmentPackageDetails,
      )
    }
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[458px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Add New Package</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 px-4 h-[300px] overflow-y-auto styled-scroll'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-flex-col pr-4 w-2/3'>
              <p className='text-xs font-medium text-N-700 pb-2'>
                Package Type
              </p>
              <SimpleSelect
                options={packageTypes}
                value={shipmentPackageDetails.packageType}
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    packageType: e,
                  })
                }}
              />
            </div>
            <div className='flex-flex-col pl-4 w-1/3'>
              <p className='text-xs font-medium text-N-700 pb-2'>Quantity</p>
              <Input
                type='text'
                isDisabled={
                  selectedOrderDetails?.warehouse?.warehouseIntegration
                    ?.name === 'IcsWMS'
                }
                value={shipmentPackageDetails.packageQty}
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    packageQty: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className='flex items-center justify-between w-full pt-6'>
            <div className='flex-flex-col pr-4 w-2/3'>
              <div className='flex pb-2'>
                <span className='text-xs font-medium text-N-700 '>
                  Dimensions (CM) - (L x W x H per unit)
                </span>
                <span className='text-R-500 leading-[16px] ml-[2px]'>*</span>
              </div>

              <div className='flex'>
                <Input
                  type='text'
                  inputClassName='rounded-r-none'
                  placeholder='0'
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      length: e.target.value,
                    })
                  }}
                />
                <Input
                  type='text'
                  inputClassName='rounded-none'
                  placeholder='0'
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      width: e.target.value,
                    })
                  }}
                />
                <Input
                  type='text'
                  inputClassName='rounded-l-none'
                  placeholder='0'
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      height: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className='flex-flex-col pl-4 w-1/3'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Weight (KG)
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px] '>*</span>
              </div>

              <Input
                type='text'
                placeholder='0'
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    weight: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          {userDetails?.userPermissions?.find(
            (item: any) => item === 'PackageTemplateEditAccess',
          ) &&
            selectedOrderDetails?.warehouse?.warehouseIntegration?.name ===
              'PaperlessWMS' && (
              <div>
                <div className='flex items-center justify-between w-full pt-6'>
                  <div className='flex items-center mb-4'>
                    <input
                      type='checkbox'
                      id='save-for-later'
                      className='mr-2'
                      checked={saveForLater}
                      onChange={(e) => setSaveForLater(e.target.checked)}
                    />
                    <label
                      htmlFor='save-for-later'
                      className='text-sm font-medium text-N-700'>
                      Save for later
                    </label>
                  </div>
                </div>
                {saveForLater && (
                  <div>
                    <div className='flex items-center justify-between w-full pt-3'>
                      <label className='flex items-center mr-2'>
                        <input
                          type='radio'
                          name='templateType'
                          value='Standard'
                          checked={isCustomized == false}
                          onChange={handleTemplateTypeChange}
                          className='mr-2'
                        />
                        <span className='text-sm font-medium text-N-700'>
                          Standard
                        </span>
                      </label>
                      <label className='flex items-center mr-10'>
                        <input
                          type='radio'
                          name='templateType'
                          value='Customized'
                          checked={isCustomized == true}
                          onChange={handleTemplateTypeChange}
                          className='mr-2'
                        />
                        <span className='text-sm font-medium text-N-700'>
                          Customized
                        </span>
                      </label>
                    </div>
                    {isCustomized && (
                      <div className='flex items-center justify-between w-full pt-6'>
                        <div className='flex flex-col w-1/2'>
                          <label className='text-sm font-medium text-N-700 mb-2'>
                            Client
                          </label>
                          <SimpleSelect
                            options={[
                              {
                                value:
                                  selectedOrderDetails?.partnerOrder?.partner
                                    ?.code,
                                label:
                                  selectedOrderDetails?.partnerOrder?.partner
                                    ?.name,
                              },
                            ]}
                            value={{
                              value:
                                selectedOrderDetails?.partnerOrder?.partner
                                  ?.code,
                              label:
                                selectedOrderDetails?.partnerOrder?.partner
                                  ?.name,
                            }} // Set initial value
                          />
                        </div>
                        <div className='flex flex-col w-1/4'>
                          <label className='text-sm font-medium text-N-700 mb-2'>
                            Order Type
                          </label>
                          <SimpleSelect
                            options={[
                              {
                                value:
                                  selectedOrderDetails?.partnerOrder
                                    ?.orderType || '',
                                label:
                                  selectedOrderDetails?.partnerOrder
                                    ?.orderType || 'ALL',
                              },
                            ]}
                            value={{
                              value:
                                selectedOrderDetails?.partnerOrder?.orderType ||
                                '',
                              label:
                                selectedOrderDetails?.partnerOrder?.orderType ||
                                'ALL',
                            }} // Set initial value
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <div className='flex pb-2 pt-6'>
                        <span className='text-xs font-medium text-N-700 '>
                          Template Name
                        </span>
                        <span className='text-R-500 leading-[16px] ml-[2px]'>
                          *
                        </span>
                      </div>
                      <div>
                        <Input
                          type='text'
                          className='block w-full flex-row'
                          value={templateName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setTemplateName(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
        </div>
        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 mt-9 px-5 py-4'>
          <Button
            onClick={(e: any) => {
              handleConfirmClick(e)
            }}
            disabled={isConfirmButtonDisabled}
            isLoading={loadingCreatePackage || loadingCreatePackageTemplate}>
            Add Package
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

AddNewPackageModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AddNewPackageModal
