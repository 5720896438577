/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { prefix } from '@fortawesome/free-solid-svg-icons'
import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { JsonLoader } from 'components/atoms/JsonLoader'
import { PopUpAlert } from 'components/atoms/PopUpAlert'
import { IntegrationPanel } from 'components/molecules/IntegrationPanel'

export interface PartnerIntegrationProps {
  className?: string | undefined
  [x: string]: any
}

export const PartnerIntegration: FC<PartnerIntegrationProps> = ({
  className,
  getPartnerTableList,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
  useDeletePartnerIntegrationById,
  useGetPartnerIntegrationById,
  useGetWarehouseList,
  useUpdateIntegration,
}: PartnerIntegrationProps) => {
  const AddPartnerClasses = CN(
    'AddPartner flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto',
    className,
    {},
  )
  const [showIntegrationDropDown, setShowIntegrationDropDown] = useState(false)
  const [newIntegrations, setNewIntegrations] = useState([] as any)
  const [wareHouseList, setWareHouseList] = useState([] as any)
  const [sectionKey, setSectionKey] = useState(Math.random())
  const [isDisabledUpdateButton, setIsDisabledUpdateButton] = useState(false)
  const [
    deletePartnerIntegrationPopOutIsOpen,
    setDeletePartnerIntegrationPopOutIsOpen,
  ] = useState(false)
  const [selectedIndexOfIntegration, setSelectedIndexOfIntegration] =
    useState(null)

  /** API Call using react-query */
  const {
    mutate: getPartnerIntegrationByIdMutate,
    isLoading: getPartnerIntegrationByIdIsLoading,
  } = useGetPartnerIntegrationById()
  const {
    mutate: deletePartnerIntegrationByIdMutate,
    isLoading: deletePartnerIntegrationByIdIsLoading,
  } = useDeletePartnerIntegrationById()
  const {
    mutate: updateIntegrationMutate,
    isLoading: updateIntegrationIsLoading,
  } = useUpdateIntegration()

  const { mutate: getWarehouseListMutate } = useGetWarehouseList()

  const chooseIntegration = (event: any, selectedIntegration: string) => {
    event.stopPropagation()
    const toBeAdded = newIntegrations
    if (selectedIntegration === 'Shopify') {
      toBeAdded.push({
        type: 'new',
        id: '',
        newId: toBeAdded.length + 1,
        name: selectedIntegration,
        version: '#N/A',
        isActive: true,
        extractOrders: true,
        updateTrackingInfo: true,
        updateShippingInfo: true,
        enabledSubCarrierAccount: false,
        isStockValidating: false,
        wareHouse: '',
        apiBaseUrl: '',
        apiPassword: '',
        locationId: {},
        prefix: '',
        suffix: '',
      })
    }

    if (selectedIntegration === 'Woocommerce') {
      toBeAdded.push({
        type: 'new',
        id: '',
        newId: toBeAdded.length + 1,
        name: selectedIntegration,
        version: '#N/A',
        isActive: true,
        extractOrders: true,
        updateTrackingInfo: true,
        updateShippingInfo: true,
        enabledSubCarrierAccount: false,
        isStockValidating: true,
        wareHouse: '',
        apiBaseUrl: '',
        consumerKey: '',
        consumerSecret: '',
      })
    }

    if (selectedIntegration === 'PushApi') {
      toBeAdded.push({
        type: 'new',
        id: '',
        newId: toBeAdded.length + 1,
        name: selectedIntegration,
        version: '#N/A',
        isActive: true,
        extractOrders: true,
        updateTrackingInfo: true,
        updateShippingInfo: true,
        enabledSubCarrierAccount: false,
        isStockValidating: true,
        wareHouse: '',
        apiKey: '',
      })
    }

    setNewIntegrations(toBeAdded.slice())
    setShowIntegrationDropDown(false)
  }

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { id: '123' },
      {
        onSuccess: ({ data: successData }: any) => {
          setWareHouseList(successData.warehouseCodeNameModelList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** Process to get partner Integrations */
  async function getPartnerIntegrationById(id: any): Promise<void> {
    getPartnerIntegrationByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          if (successData.detailedPartnerIntegrations.length > 0) {
            const toBeAdded = successData.detailedPartnerIntegrations.map(
              (item: any) => {
                if (item.name === 'Shopify') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: item.isActive,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                    isStockValidating: item.isStockValidating,
                    wareHouse: {
                      value: item.warehouses[0]?.id,
                      label: item.warehouses[0]?.name,
                    },
                    apiBaseUrl: item.baseUrl,
                    apiPassword: item.apiPassword,
                    locationId: {
                      value: item.locationId,
                      label: item.locationId,
                    },
                    prefix: item.orderNoPrefix ?? '',
                    suffix: item.orderNoSuffix ?? '',
                  }
                }
                if (item.name === 'Woocommerce') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: item.isActive,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                    isStockValidating: item.isStockValidating,
                    wareHouse: {
                      value: item.warehouses[0]?.id,
                      label: item.warehouses[0]?.name,
                    },
                    apiBaseUrl: item.baseUrl,
                    consumerKey: item.consumerKey,
                    consumerSecret: item.consumerSecret,
                  }
                }
                if (item.name === 'PushApi') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: item.isActive,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                    isStockValidating: item.isStockValidating,
                    wareHouse: {
                      value: item.warehouses[0]?.id,
                      label: item.warehouses[0]?.name,
                    },
                    apiKey: item.apiKey,
                  }
                }
              },
            )
            setNewIntegrations(toBeAdded?.slice())
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
          onCloseClick()
        },
      },
    )
  }

  /** Process Delete Integration By ID  & remove it from Existing integration Array*/
  async function deletePartnerIntegrationById(
    partnerId: any,
    IntegrationId: any,
    toBeUpdated: any,
    indexValue: any,
  ): Promise<void> {
    deletePartnerIntegrationByIdMutate(
      { partnerId: partnerId, integrationId: IntegrationId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          toBeUpdated.splice(indexValue, 1)
          setNewIntegrations(toBeUpdated.slice())
          setSectionKey(Math.random())
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  useEffect(() => {
    getPartnerIntegrationById(selectedRowByButtonAction.id)
    getWarehouseList()
  }, [selectedRowByButtonAction])

  /** Checking Fields are valid or not in each integration and set setIsDisabledUpdateButton to true if any of them is invalid */
  useEffect(() => {
    let shopifyValidity = true
    let wooCommerceValidity = true
    let pushAPIValidity = true
    if (newIntegrations.length > 0) {
      const shopifyIntegration = newIntegrations.filter(
        (item: any) => item.name === 'Shopify',
      )
      const woocommerceIntegration = newIntegrations.filter(
        (item: any) => item.name === 'Woocommerce',
      )

      const pushAPIIntegration = newIntegrations.filter(
        (item: any) => item.name === 'PushApi',
      )

      if (shopifyIntegration.length > 0) {
        const checkeditems: any[] = []
        shopifyIntegration.forEach((item: any) => {
          const { apiBaseUrl, apiPassword, locationId } = item
          const key = `${apiBaseUrl}_${apiPassword}_${locationId.value}`
          checkeditems.push(key)
        })
        const checkedItemsSet = new Set(checkeditems)
        const hasDuplicates = checkeditems.length > checkedItemsSet.size
        const isValidShopify = shopifyIntegration.every(
          (item: any) =>
            item.apiBaseUrl.length > 0 &&
            item.wareHouse.value?.length > 0 &&
            item.apiPassword.length > 0,
        )
        shopifyValidity = isValidShopify && !hasDuplicates
      }
      if (woocommerceIntegration.length > 0) {
        const isValidWoocomerce = woocommerceIntegration.every(
          (item: any) =>
            item.apiBaseUrl.length > 0 &&
            item.consumerKey.length > 0 &&
            item.wareHouse.value?.length > 0 &&
            item.consumerSecret.length > 0,
        )
        wooCommerceValidity = isValidWoocomerce
      }

      if (pushAPIIntegration.length > 0) {
        const isValidPushAPI = pushAPIIntegration.every(
          (item: any) => item.wareHouse.value?.length > 0,
        )
        pushAPIValidity = isValidPushAPI
      }
      setIsDisabledUpdateButton(
        !(shopifyValidity && wooCommerceValidity && pushAPIValidity),
      )
    }
  }, [newIntegrations])

  /** Deleting the Integrations individually */
  const deleteThisIntegration = (indexValue: any) => {
    setDeletePartnerIntegrationPopOutIsOpen(false)

    const { type } = newIntegrations[indexValue]
    const toBeUpdated = newIntegrations
    if (type === 'new') {
      toBeUpdated.splice(indexValue, 1)
      setNewIntegrations(toBeUpdated.slice())
      setSectionKey(Math.random())
    }
    if (type === 'existing') {
      deletePartnerIntegrationById(
        selectedRowByButtonAction.id,
        newIntegrations[indexValue].id,
        toBeUpdated,
        indexValue,
      )
    }
  }

  const updateIntegration = () => {
    /** Separate the Shopify and Woocommerce integration */
    const shopifyIntegration = newIntegrations.filter(
      (item: any) => item.name === 'Shopify',
    )
    const woocommerceIntegration = newIntegrations.filter(
      (item: any) => item.name === 'Woocommerce',
    )

    const pushAPIIntegration = newIntegrations.filter(
      (item: any) => item.name === 'PushApi',
    )

    /** Making Shopify object for the Back ENd */
    const shopifyObject = shopifyIntegration.map((item: any) => {
      return {
        id: item.id || null,
        extractOrders: item.extractOrders,
        updateTrackingInfo: item.updateTrackingInfo,
        updateShippingInfo: item.updateShippingInfo,
        enabledSubCarrierAccount: item.enabledSubCarrierAccount,
        isStockValidating: item.isStockValidating,
        warehouseId: item.wareHouse.value,
        baseUrl: item.apiBaseUrl,
        apiPassword: item.apiPassword,
        locationId: item.locationId.value || '',
        isActive: item.isActive,
        prefix: item.prefix,
        suffix: item.suffix,
      }
    })

    /** Making Woocommerce object for the Back End */
    const woocommerceObject = woocommerceIntegration.map((item: any) => {
      return {
        id: item.id || null,
        extractOrders: item.extractOrders,
        updateTrackingInfo: item.updateTrackingInfo,
        updateShippingInfo: item.updateShippingInfo,
        enabledSubCarrierAccount: item.enabledSubCarrierAccount,
        isStockValidating: item.isStockValidating,
        warehouseId: item.wareHouse.value,
        baseUrl: item.apiBaseUrl,
        consumerKey: item.consumerKey,
        consumerSecret: item.consumerSecret,
        isActive: item.isActive,
      }
    })

    /** Making pushAPi object for the Back End */
    const pushApiObject = pushAPIIntegration.map((item: any) => {
      return {
        id: item.id || null,
        extractOrders: item.extractOrders,
        updateTrackingInfo: item.updateTrackingInfo,
        updateShippingInfo: item.updateShippingInfo,
        enabledSubCarrierAccount: item.enabledSubCarrierAccount,
        isStockValidating: item.isStockValidating,
        warehouseId: item.wareHouse.value,
        isActive: item.isActive,
      }
    })

    /** Call Mutate with Created Objects */
    updateIntegrationMutate(
      {
        partnerId: selectedRowByButtonAction.id,
        wooCommerce: woocommerceObject,
        shopify: shopifyObject,
        pushApi: pushApiObject,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          onCloseClick()
          getPartnerTableList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      title={`Add New Integration - ${selectedRowByButtonAction.name}`}>
      <div className={AddPartnerClasses}>
        {(getPartnerIntegrationByIdIsLoading ||
          deletePartnerIntegrationByIdIsLoading ||
          updateIntegrationIsLoading) && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}
        <div className='flex bg-N-50 w-full justify-between items-center px-[30px] py-4 rounded-lg mt-6 cursor-pointer'>
          <div className='flex flex-col justify-center'>
            <h2 className='text-lg font-bold text-N-700'>Integration</h2>
            <p className='text-sm text-N-700 pt-1'>
              Integrate with 3rd party plugins (You can add multiple
              integrations)
            </p>
          </div>
          <div
            className='relative'
            onClick={() => {
              setShowIntegrationDropDown(!showIntegrationDropDown)
            }}>
            <i className='ri-add-circle-line text-P-500 text-4xl'></i>
            {showIntegrationDropDown && (
              <ul className='drop-me absolute right-0 top-[110%] bg-white w-40 rounded-lg shadow-2xl'>
                <li
                  onClick={(event: any) => {
                    chooseIntegration(event, 'Shopify')
                  }}>
                  <div className='flex items-center p-[14px] hover:bg-N-50 active:bg-N-100 rounded-t-lg'>
                    <img src={IconShopify} alt='IconShopify' />
                    <p className='text-sm pl-[10px]'>Shopify</p>
                  </div>
                </li>
                <li
                  onClick={(event: any) => {
                    chooseIntegration(event, 'Woocommerce')
                  }}>
                  <div className='flex items-center p-[14px] hover:bg-N-50 active:bg-N-100 rounded-b-lg'>
                    <img src={Iconwoocommerce} alt='Iconwoocommerce' />
                    <p className='text-sm pl-[10px]'>WooCommerce</p>
                  </div>
                </li>
                <li
                  onClick={(event: any) => {
                    chooseIntegration(event, 'PushApi')
                  }}>
                  <div className='flex items-center p-[14px] hover:bg-N-50 active:bg-N-100 rounded-b-lg'>
                    <img src={IconPushAPI} alt='iconPushAPI' />
                    <p className='text-sm pl-[10px]'>PushAPI</p>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {newIntegrations.length === 0 && (
          <div className='flex w-full h-full items-center justify-center flex-col'>
            <h2 className='text-2xl text-N-700 font-medium'>
              No Integrations Found
            </h2>
            <p className='font-normal text-N-500'>
              You can add integrations by clicking on above orange plus button
            </p>
          </div>
        )}

        <div key={sectionKey}>
          {newIntegrations.length > 0 &&
            newIntegrations.map((integration: any, idx: any) => (
              <IntegrationPanel
                selectedRowByButtonAction={selectedRowByButtonAction}
                IntegrationData={integration}
                setNewIntegrations={setNewIntegrations}
                newIntegrations={newIntegrations}
                key={idx}
                indexValue={idx}
                setDeletePartnerIntegrationPopOutIsOpen={
                  setDeletePartnerIntegrationPopOutIsOpen
                }
                setSelectedIndexOfIntegration={setSelectedIndexOfIntegration}
                wareHouseList={wareHouseList}
                notify={notify}
              />
            ))}
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
        <Button
          disabled={isDisabledUpdateButton}
          isLoading={
            getPartnerIntegrationByIdIsLoading ||
            deletePartnerIntegrationByIdIsLoading ||
            updateIntegrationIsLoading
          }
          onClick={() => updateIntegration()}>
          Save Integration
        </Button>
        <Button
          className='ml-5 w-[152px]'
          appearance='dull'
          onClick={() => {
            onCloseClick()
            getPartnerTableList()
          }}>
          Close
        </Button>
      </div>
      {deletePartnerIntegrationPopOutIsOpen && (
        <PopUpAlert
          isActive={deletePartnerIntegrationPopOutIsOpen}
          title='Delete Integration?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() => setDeletePartnerIntegrationPopOutIsOpen(false)}
          onOverlayClick={() => setDeletePartnerIntegrationPopOutIsOpen(false)}
          onConfirmClick={() => {
            deleteThisIntegration(selectedIndexOfIntegration)
          }}
        />
      )}
    </Drawer>
  )
}

PartnerIntegration.defaultProps = {
  className: undefined,
}

export default PartnerIntegration
