import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPackageTemplatesTableInterface {
  take?: number
  skip?: number
  Search?: string
  isCustomized: boolean
  partnerCode?: string
  orderType?: string
}

export const useGetPackageTemplatesTable = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      Search,
      isCustomized,
      partnerCode,
      orderType,
    }: GetPackageTemplatesTableInterface) => {
      return get(
        `/PackageTemplateList?isCustomized=${isCustomized}&partnercode=${partnerCode}&orderType=${orderType}`,
      )
    },
  )

  return mutate
}

export default useGetPackageTemplatesTable
