import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'

import { Badge } from 'components/atoms/Badge'
import { dateAndTimeInToReadableTimeOnlyFormat } from 'utils'

export const columns = [
  {
    customWidth: 70,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (
    // ),
  },
  {
    customWidth: 160,
    Header: () => 'Warehouse Code',
    accessor: 'warehouseCode',
    Cell: ({ row }) => (
      <div className='min-w-[160px]'>{row?.original?.whCode}</div>
    ),
  },
  {
    customWidth: 180,
    Header: () => 'Partner Name',
    accessor: 'partnerName',
    Cell: ({ row }) => (
      <div className='min-w-[180px]'>{row?.original?.partnerName}</div>
    ),
  },
  {
    customWidth: 200,
    Header: () => 'Integration',
    accessor: 'integrationType',
    Cell: ({ row }) => (
      <div className='flex gap-x-2 items-center min-w-[200px]'>
        {row?.original?.integrationType === 'Shopify' && (
          <img
            src={IconShopify}
            alt='australia-post-icon'
            className='w-6 h-6'
          />
        )}
        {row?.original?.integrationType === 'Woocommerce' && (
          <img
            src={Iconwoocommerce}
            alt='australia-post-icon'
            className='w-6 h-6'
          />
        )}
        {row?.original?.integrationType === 'PushApi' && (
          <img
            src={IconPushAPI}
            alt='australia-post-icon'
            className='w-6 h-6'
          />
        )}
        {row?.original?.integrationType === 'AzureSftp' && (
          <i className='ri-folder-received-fill text-base ml-2 text-N-600'></i>
        )}
        {row?.original?.integrationType === 'PushApi'
          ? row?.original?.integrationType
          : row?.original?.integrationType === 'AzureSftp'
          ? row?.original?.integrationType +
            ` - ` +
            row?.original?.container?.toUpperCase()
          : `https://` + row?.original?.integrationURL}
      </div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'Cut-off Time',
    accessor: 'cutofffTime',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {dateAndTimeInToReadableTimeOnlyFormat(row?.original?.cutofffTime)}
      </div>
    ),
  },
  {
    customWidth: 200,
    Header: () => 'Before Cut-off Lead Time',
    accessor: 'beforeCutOffLeadTime',
    Cell: ({ row }) => (
      <div className='min-w-[200px]'>
        {row?.original?.beforeCutOffLeadTime} Day
        {row?.original?.beforeCutOffLeadTime > 1 ? 's' : ''}
      </div>
    ),
  },
  {
    customWidth: 200,
    Header: () => 'After Cut-off Lead Time',
    accessor: 'afterCutOffLeadTime',
    Cell: ({ row }) => (
      <div className='min-w-[200px]'>
        {row?.original?.afterCutOffLeadTime} Day
        {row?.original?.afterCutOffLeadTime > 1 ? 's' : ''}
      </div>
    ),
  },
  {
    id: 'status',
    customWidth: 100,
    Header: () => 'Status',
    accessor: (row) => {
      return row?.status ? 'ACTIVE' : 'INACTIVE'
    },
    Cell: ({ row }) => (
      <div>
        {row.original.status ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]
export default columns
