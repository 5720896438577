import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ausPostManifestInterface {
  WhOrderNos?: any
  CourierCode?: string
  WhCode?: string
}
export const useAusPostManifest = () => {
  const mutate = useMutation(
    ({ WhOrderNos, CourierCode, WhCode }: ausPostManifestInterface) => {
      return postAPI('/Manifests/ManifestAusPostOrders', {
        WhOrderNos,
        CourierCode,
        WhCode,
      })
    },
  )
  return mutate
}
export default useAusPostManifest
