import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePackageTemplateInterface {
  packageTemplateId?: string
}

export const useDeletePackageTemplate = () => {
  const mutate = useMutation(
    ({ packageTemplateId }: DeletePackageTemplateInterface) => {
      return deleteAPI(`/PackageTemplate/${packageTemplateId}`)
    },
  )

  return mutate
}

export default useDeletePackageTemplate
